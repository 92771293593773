<template>
  <div>
    <BaseButton
      v-if="$usesFeature('classic-jobs')"
      data-tour-tc="step-6"
      :size="size"
      @click="openAddJobModal"
    >
      <template slot="iconLeft">
        <Icon
          view-box="0 0 24 24"
          class="w-5 h-5"
        >
          <Plus />
        </Icon>
      </template>
      Create Assessment
    </BaseButton>

    <BaseButton
      v-if="$usesFeature('candidate-wizard')"
      :to="{name: 'job-create'}"
    >
      <template slot="iconLeft">
        <Icon
          view-box="0 0 24 24"
          class="w-5 h-5 mr-4"
        >
          <Plus />
        </Icon>
      </template>
      Add job
    </BaseButton>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Create an assessment
          </h3>
          <button
            v-if="($tours['onboarding'] && !$tours['onboarding'].isRunning) || !$tours['onboarding']"
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <AddJobForm
        v-if="$usesFeature('classic-jobs')"
        data-tour-tc="step-7"
        @close="close"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import AddJobForm from '@components/Jobs/AddJobForm'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    Modal,
    AddJobForm,
    Icon,
    Plus
  },

  props: {
    size: {
      type: String,
      default: 'standard'
    }
  },

  data() {
    return {
      modalOpen: false
    }
  },

  watch: {
    '$tours.onboarding.currentStep'(value) {
    // Dont open the Add job modal if the Tour step is skipped
      if (value !== 7) {
        this.modalOpen = false
      }
    }
  },

  methods: {
    close() {
      this.modalOpen = false
      this.$emit('close')

      if (this.$tours['onboarding'] && this.$tours['onboarding'].isRunning) {
        setTimeout(() => {
          this.$tours['onboarding'].currentStep = 8
        }, 500)
      }
    },

    openAddJobModal() {
      this.modalOpen = true

      if (this.$tours['onboarding'] && this.$tours['onboarding'].isRunning) {
        setTimeout(() => {
          this.$tours['onboarding'].currentStep = 7
        }, 500)
      }
    }
  }
}
</script>
