<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Tests</BasePageHeading>
      </div>
      <BasePageActions>
        <CreateAssessmentButton />
      </BasePageActions>
    </BasePageHeader>

    <BaseTabBar>
      <BaseTabs>
      </BaseTabs>
      <div class="w-1/3 mb-3">
        <Select
          placeholder="Filter Tests"
          :options="filterOptions"
          @selected="filterBy($event)"
        >
        </Select>
      </div>
    </BaseTabBar>

    <ExamsLibrary
      :use-add-option="false"
    />
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs></BasePageBreadcrumbs>
        <BasePageHeading></BasePageHeading>
      </div>
    </BasePageHeader>
    <Loader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
import CreateAssessmentButton from '@components/ClientAssessments/CreateAssessmentButton'
import ExamsLibrary from '@components/Exams/ExamsLibrary'
import Loader from '@components/Loader'
import Select from '@components/TUI/Select'

import { mapGetters } from 'vuex'
import states from '@api/states'

export default {
  components: {
    CreateAssessmentButton,
    ExamsLibrary,
    Loader,
    Select
  },

  data() {
    return {
      states,

      error: null
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name',
      examSuiteOptions: 'clientExams/examSuiteOptions',
      customExams: 'clientExams/customExams'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }

      if (!this.examSuiteOptions.length === 0) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * @return {Array}
     */
    filterOptions() {
      const options = this.examSuiteOptions

      if (this.customExams) {
        options.push({
          label: 'Custom Tests',
          value: 'custom-exams'
        })
      }

      return options
    }
  },

  mounted() {
    this.filterBy()
  },

  methods: {
    /**
     * Re-dispatch client exams based on the current filter
     *
     * @param {string} examSuiteSlug
     * @return void
     */
    filterBy(examSuiteSlug) {
      this.$store.dispatch('clientExams/getClientExams', examSuiteSlug)
    }
  }
}
</script>
